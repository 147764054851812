.card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 24px;
    padding: 20px;
    background-color: #fff;
    width: 400px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
    font-family: 'Roboto', sans-serif;
    /* Font untuk card */
}

.card-item:hover {
    transform: scale(1.02);
    /* Membesarkan card sebesar 5% pada X dan Y */
}

.card-item-img {
    width: 100%;
    height: 190px;
    border-radius: 15px;
    /* Sudut melengkung yang lebih besar */
    border: 5px solid transparent;
    /* Border transparan untuk efek */
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
    /* Gradient border */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    /* Bayangan yang lebih besar */
    margin-bottom: 20px;
}

.card-item-details {
    text-align: center;
    margin-bottom: 15px;
}

.card-item-name {
    font-size: 1.2em;
    font-weight: 700;
    /* Bold font weight */
    color: #333;
    /* Dark color for better readability */
    margin-bottom: 5px;
}

.card-item-category {
    font-size: 0.9em;
    font-weight: 400;
    /* Normal font weight */
    color: #777;
    /* Lighter color for category */
}

.card-item-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.card-button {
    border-radius: 10px;
    padding: 12px 20px;
    border: none;
    width: 100%;
    max-width: 200px;
    margin: 5px 0;
    color: white;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'Roboto', sans-serif;
}

.card-button-android {
    background-color: rgb(13, 190, 102);
    /* Hijau untuk Android */
}

.card-button-android:hover {
    background-color: rgb(10, 150, 80);
    /* Hijau lebih gelap saat ditekan */
}

.card-button-ios {
    background-color: rgb(0, 122, 255);
    /* Biru untuk iOS */
}

.card-button-ios:hover {
    background-color: rgb(0, 94, 215);
    /* Biru lebih gelap saat ditekan */
}

.card-button-pressed {
    background-color: rgb(0, 94, 215);
    /* Biru lebih gelap saat ditekan */
}

.card-button-pressed-android {
    background-color: rgb(10, 150, 80);
    /* Hijau lebih gelap saat ditekan */
}

.card-button-disabled,
.card-button-disabled:hover {
    background-color: #ccc;
    /* Warna abu-abu untuk tombol dinonaktifkan */
    cursor: not-allowed;
    opacity: 0.6;
}


@media (max-width: 520px) {
    .card-item {
        width: 400px;
    }
}

@media (max-width: 480px) {
    .card-item {
        width: 320px;
    }

    .card-item-img {
        height: 150px;
    }

    .card-item-name {
        font-size: 1.1em;
    }
}

@media (max-width: 380px) {
    .card-item {
        width: 270px;
    }

    .card-item-img {
        height: 130px;
    }

    .card-item-name {
        font-size: 1em;
    }
}

@media (max-width: 330px) {
    .card-item {
        width: 240px;
    }

    .card-item-name {
        font-size: 1em;
    }
}