.card-item-itchio {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center horizontally */
  justify-content: center;
  /* Center vertically */
  border: 1px solid #ddd;
  border-radius: 24px;
  padding: 20px;
  background-color: #fff;
  width: 400px;
  height: 375px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  font-family: 'Roboto', sans-serif;
}

.card-item-itchio:hover {
  transform: scale(1.02);
}

.iframe-container-itchio {
  display: flex;
  /* Flexbox to center iframe */
  align-items: center;
  /* Center vertically */
  justify-content: center;
  /* Center horizontally */
  width: 290px;
  height: 267px;
  background-color: #3f3c3c;
  border: 4px solid #ec5858;
  border-radius: 24px;
  /* Match the border-radius of iframe */
  overflow: hidden;
  /* Hide overflow to ensure border fits */
  position: relative;
  /* Required for absolute positioning of iframe */
}

@media (max-width: 480px) {
  .card-item-itchio {
    width: 320px;
    height: 335px;
  }

  .iframe-container-itchio {
    width: 250px;
    height: 220px;
  }
}

@media (max-width: 380px) {
  .card-item-itchio {
    width: 270px;
    height: 313px;
  }

  .iframe-container-itchio {
    width: 210px;
    height: 200px;
  }
}

@media (max-width: 330px) {
  .card-item-itchio {
    width: 240px;
    height: 310px;
  }

  .iframe-container-itchio {
    width: 210px;
    height: 200px;
  }
}