.card-profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
    background-color: rgba(255, 255, 255, 0);
    /* Warna latar belakang */
    height: auto;
    /* Tinggi otomatis berdasarkan konten */
}

.card-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    width: 300px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
}

.card-profile:hover {
    transform: scale(1.02);
    /* Membesarkan card sebesar 5% pada X dan Y */
}

.img-profile {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 4px solid #a32929;
    /* Garis bawah untuk profil */
}

.card-profile-content {
    padding: 10px 20px 20px 20px;
    text-align: center;
}

.title-profile {
    font-size: 1em;
    /* Font size for prominence */
    font-weight: 600;
    /* Semi-bold font weight */
    color: #a32929;
    /* Color for readability */
    margin-bottom: 10px;
    /* Margin below the title */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    /* Soft shadow for depth */
    letter-spacing: 0.5px;
    /* Increased letter spacing */
    font-family: 'Roboto', sans-serif;
    /* Modern font family */
    position: relative;
    /* Positioning for the underline effect */
}

.title-profile::after {
    content: '';
    /* No content needed for the pseudo-element */
    display: block;
    /* Makes the pseudo-element a block-level element */
    width: 70%;
    /* Underline width relative to the title */
    height: 4px;
    /* Thickness of the underline */
    background: #a32929;
    /* Color of the underline */
    position: absolute;
    /* Absolute positioning for precise placement */
    bottom: -5px;
    /* Positioning the underline just below the text */
    left: 50%;
    /* Center the underline horizontally */
    transform: translateX(-50%);
    /* Center the underline with respect to the title */
    border-radius: 2px;
    /* Rounded corners for the underline */
}

.name-profile {
    font-size: 1.2em;
    /* Slightly larger font size for better prominence */
    font-weight: 700;
    /* Bold for emphasis */
    color: #000000;
    /* Darker color for better contrast */
    margin-bottom: 8px;
    /* Reduced margin for a tighter layout */
    font-family: 'Roboto', sans-serif;
    /* Clean, modern font */
    letter-spacing: 1px;
    /* Adds spacing between letters for a refined look */
}

.bio-profile {
    font-size: 1em;
    /* Consistent font size for readability */
    color: #555;
    /* Slightly darker color for better readability */
    margin-bottom: 20px;
    /* Consistent spacing from other elements */
    font-family: 'Roboto', sans-serif;
    /* Matches the font family used in the name */
    line-height: 1.5;
    /* Adds line height for improved readability */
}

.button-contact-profile {
    background: #a32929;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.button-contact-profile:hover {
    background-color: #832929;
    transform: scale(1.05);
}

@media (max-width: 340px) {
    .card-profile-container {
        margin-top: 35px;
    }

    .card-profile {
        width: 80%;
        /* Adjust card width for small screens */
    }

    .img-profile {
        height: 150px;
        /* Adjust image height for small screens */
    }

    .title-profile,
    .name-profile,
    .bio-profile {
        font-size: 0.9em;
        /* Adjust font size for small screens */
    }

    .button-contact-profile {
        padding: 8px 16px;
        /* Adjust padding for small screens */
        font-size: 0.9em;
        /* Adjust font size for small screens */
    }
}