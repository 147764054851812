body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;  
  background-image: url('./assets/bg.jpg');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 55px;
  color: white;
  text-align: center;
}

.neonText {
  color: rgb(255, 255, 255);
  text-shadow:
      0 0 7px rgb(255, 255, 255),
      0 0 10px rgb(0, 0, 0),
      0 0 21px rgb(112, 112, 112),
      0 0 42px rgb(83, 83, 83),
      0 0 82px rgb(250, 250, 250),
      0 0 92px rgb(255, 255, 255),
      0 0 102px rgb(255, 255, 255),
      0 0 151px rgb(255, 255, 255);
}

@media screen and (max-width: 450px) {
  h1 {
    font-size: 45px;
  }
}

@media screen and (max-width: 320px) {
  h1 {
    font-size: 35px;
  }
}
