.carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 480px; /* Sesuaikan dengan kebutuhan */
    padding-top: 5px;
}

.inner {
    display: flex;
    transition: transform 0.3s ease-in-out;
}

.carousel-item {
    flex: 0 0 auto;  /* Item akan memiliki lebar sesuai dengan ukuran kontennya */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000; /* Warna teks default */
    border-radius: 15px; /* Sudut melengkung */
}

.indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    gap: 10px;
}

.indicator {
    width: 15px;
    height: 15px;
    background-color: #ffffff; /* Semi-transparent white */
    border: 2px solid #ff7e5f; /* Outline border for contrast */
    cursor: pointer; /* Pointer cursor for interactivity */
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

.indicator:hover {
    background-color: #ff7e5f; /* Color on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow effect on hover */
    transform: scale(1.2); /* Slightly enlarge on hover */
}

.indicator.active {
    background-color: #ff7e5f; /* Active color */
    border: 2px solid #ffffff; /* Contrast border for active state */
}

@media (max-width: 480px) {
    .carousel {
        height: 435px;
    }
}

@media (max-width: 380px) {
    .carousel {
        height: 405px;
    }
}
